.CancelRequestModal {
  .ant-modal-close {
    display: none;
  }

  .warning-message {
    display: flex;
    justify-content: center;
  }

  .ant-modal-footer {

    > div {
      display: flex;
      justify-content: center;

      button:first-child {
        display: none;
      }
    }
  }
}