@import "../../../styles/color.scss";
@import "../../../styles/mixins.scss";

.share-modal {
  // remove Ant'd bottom margin on Form.Item elements
  &__input.ant-form-item {
    margin-bottom: 0;
  }

  // the "share" button
  &__btn.ant-btn.ant-btn-primary {
    height: 30px;
    border: none;
  }

  // distribute space between OK and Cancel buttons
  .ant-modal-footer > div {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    @include font(16, 500, $blue-dark, null, 24);
  }

  // override Ant'd padding so the emails' list text
  // can be aligned with the input text
  &__item {
    // default unused space to be removed
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-list-item {
      padding: 6px 12px 3px 12px;
      // last row with more space with the error below
      &:last-child {
        padding: 6px 12px 12px 12px;
      }
    }

    // the color needed when showing error message read from Redux
    &--error {
      color: $error-red;
    }
  }

  // reduces the space between the last email in list and the footer
  .ant-modal-body {
    padding-bottom: 12px !important;
  }

  &__item {
    line-height: 1.5;
  }

  // AntD red error message below email field always 22px
  // setting it to auto means zero height if no element is shown
  .ant-form-explain {
    min-height: auto !important;
  }

  // .ant-row.ant-form-item.share-modal__item.share-modal__item--error {
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  // the button-addon "Share" --> padding override to make it fit
  // the parent div container
  .ant-input-group-addon {
    padding: 0 !important;
  }

  // reduce the list inside the modal to not overflow
  .ant-list-items {
    max-height: 70vh;
    overflow-y: auto;
  }
}
