.icons-menu {
  // center vertically its children
  display: flex;
  align-items: center;

  // the square-link contains the icon
  a {
    background-color: transparent;
  }

  // the icon - defaults are 14px
  i {
    height: 24px;
    width: 24px;
  }
}
