.as-ant-button {
  color: rgb(255, 255, 255);
  background-color: rgb(24, 144, 255);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  width: 100%;
  text-transform: uppercase;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;

  font-weight: 400;
  text-align: center;
  border: 1px solid rgb(24, 144, 255);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;

  display: block;
  line-height: 39px;
}