@import "../../../styles/mixins.scss";
@import "../../../styles/color.scss";

.modal-form {
  // "Ok" / "Confirm" and "Cancel" buttons
  &__buttons {
    &.ant-col.ant-col-24 {
      display: flex;
      justify-content: space-between;
    }

    // used in case when there's only one button, to put it at right
    &--reverse {
      flex-direction: row-reverse; //  todo da usare solo se 1 bottone, flex-end se c'è solo il confirm
    }
  }
}
