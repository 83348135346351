@import "./styles/color.scss";
@import "./styles/mixins.scss";
@import "./styles/responsive.scss";
// @import "./styles/helper.scss";

body {
  background-color: $grey-background;
  font-family: $font-family;
}

// overrides tcxspan { text-decoration: underline } is injected in the app (by AntDesign ?)
// will apply for example in ItemDetail "Total" price
tcxspan {
  text-decoration: none !important;
}

// MODAL common rules
.ant-modal-title {
  @include font(16, 500, $blue-dark, undefined, 24);
}

.ant-modal-footer {
  padding: 16px 24px !important;
}

// the "X" position on modals aligned to the bottom-right button
.ant-modal-close {
  min-width: auto;
  height: 56px;

  &-x {
    width: 63px !important;
  }
}

// all primary (blue) buttons with uppercase text
.ant-btn.ant-btn-primary {
  text-transform: uppercase;
}

.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  min-width: unset;
}

.truncate-long-text,
.button-with-truncate-long-text span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-popover {
  z-index: 999 !important;
}

.no-arrow {
  .ant-tooltip-arrow {
    display: none;
  }
}

.anticon.edit-icon {
  color: $blue-selected;
}
