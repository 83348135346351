@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

.settings {
  // modal: 90% of the viewport
  max-width: 90% !important;
  max-height: 90vh;
  width: auto !important;
  // overflow-y: auto;
  padding-bottom: 0 !important;

  // title
  .ant-modal-title {
    @include font(16, 500, $blue-dark, null, 24);
  }

  &__form {
    // 100% of the modal's space, with auto vertical scroll if content is longer
    width: 100% !important;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    // keeps first row (the Card components) in a row (eventually wrapped) and a second row below
    // holds the "Save" button
    flex-direction: column;
    // adjustment to keep the body container fixed (and its content scrolls vertically, in case) letting
    // header and footer always visible and fixed
    max-height: 70vh;
    padding: 16px 24px 16px 16px;

    &--techSpechFull {
      margin-top: -24px;
    }
  }

  // the single row's height is calculated by flex based on the maximum height of its columns' contents, so
  // if a column has a shorter height, it'd be visibile, with the following lines it will always take the whole height
  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
  }

  .ant-modal-body {
    padding: 0 0 0 8px;
    background-color: #eee;
  }

  .ant-form-item {
    // the space under each item item
    margin-bottom: 12px;
  }

  .ant-form-item-control {
    // override default 39px (?!?)
    line-height: 1em;
  }

  .ant-input-number-input {
    width: 100%;
  }

  // every row's height is calculated by flex
  &__card {
    display: flex !important;
    min-width: fit-content;
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
    // since the form container has overflow-y auto to let auto scroll in case content is higher than the viewport,
    // the section-number - which should overflow - is partially cut --> adding padding to every card moves content down so it shows the whole section-numbers' circle
    padding: 20px 12px 0 12px;

    .ant-form-extra {
      min-width: 20%;
      max-width: 236px;
      color: $text-color;
    }

    // under 1024px is not managed because it's out of context
    @media (min-width: 1024px) {
      max-width: 33.3333%;
    }
    @media (min-width: 1280px) {
      max-width: 25%;
    }
    @media (min-width: 1600px) {
      max-width: 20%;
    }

    // removes ant's default padding top to regain .ant-card-body padding-top
    .ant-card-body {
      padding: 0;
    }
  }

  // Field List columns: larger than others to let the two column stretch
  // #fields {
  //   min-width: fit-content;
  //   flex: 1 1 auto;
  //   max-width: 700px;
  // }

  &__content {
    position: relative;
    margin-top: 30px;
    margin-bottom: 16px !important;
    width: 100%;
    // box-shadow: -9px -9px 9px $black-shadow;
    color: $text-color;
  }

  .section-number {
    position: absolute;
    right: 25px;
    top: -20px;
    background-color: $aquamarine;
    line-height: 60px;
    width: 60px;
    text-align: center;
    border-radius: 50px;
    font-size: 0.9em;
    color: $aquamarine-dark;
    z-index: 1; // to be visibile over the card
    .big {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  &__title {
    color: $blue-dark;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.14px;
  }

  // TODO verificare se serve
  .description {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 10px 0 15px 0;
  }

  // TODO verificare se serve
  .ant-form-item-label {
    line-height: 1em;
  }

  // TODO verificare se serve
  label {
    display: inline-block;
    font-size: 12px;
    line-height: 20px; // the label height box which contains the string itself (12px)
    color: $text-color;
    margin-bottom: 5px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
