@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

.customize-modal {
  &__title {
    @include font(32, 300, $text-color, null, 42);
    margin-left: 20px;
  }

  &__container {
    background-color: $white;
    padding: 40px 24px !important;
  }

  &__section-title {
    @include font(16, bold, $aquamarine-dark, uppercase, 20);
    letter-spacing: 1.14px;
  }

  // form labels container
  &__label .ant-form-item-label {
    line-height: 10px;
    // form label text
    & label {
      @include font(12, null, $text-color, uppercase, 15);
    }
  }
}
