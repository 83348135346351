@import "../../styles/mixins.scss";

.externalLink {
  a {
    display: flex;
    align-items: center;
    @include font(21, 500, "rgb(93, 93, 93) !important", null, 22);
  }

  .face {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    @include font(1rem, 700, rgb(93, 93, 93), null, null);
    background-color: rgb(201, 201, 201);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
