// Standalone / Helper css classes

@import "./color.scss";

.margin-top {
  &--10 {
    margin-top: 10px;
  }

  &--20 {
    margin-top: 20px !important;
  }
}

.margin-bottom {
  &--20 {
    margin-bottom: 20px !important;
  }

  &--40 {
    margin-bottom: 40px !important;
  }
}

.margin-left {
  &--0 {
    margin-left: 0;
  }

  &--5 {
    margin-left: 5px;
  }

  &--8 {
    margin-left: 8px;
  }

  &--15 {
    margin-left: 15px;
  }

  &--30 {
    margin-left: 30px;
  }

  &--40 {
    margin-left: 40px;
  }
}

.margin-right {
  &--8 {
    margin-right: 8px;
  }

  &--20 {
    margin-right: 20px !important;
  }
}

.padding--20 {
  padding: 20px;
}

.text-align {
  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left !important;
  }
}

.float {
  &--right {
    float: right;
  }
}

.height {
  &--40 {
    height: 40px !important;
  }
}

.display {
  &--inline-block {
    display: inline-block;
  }

  &--none {
    display: none !important;
  }

  &--flex {
    display: flex !important;
  }
}

.text-transform {
  &--uppercase {
    text-transform: uppercase;
  }
}

.padding {
  &-left {
    &--8 {
      padding-left: 8px;
    }
  }

  &-right {
    &--8 {
      padding-right: 8px;
    }
  }
}
