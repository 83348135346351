@import "../../../../styles/color";
@import "../../../../styles/mixins";

.saveAndFinishModal {
  // form labels container
  &__label .ant-form-item-label {
    line-height: 10px;
    // form label text
    & label {
      @include font(12, null, $text-color, uppercase, 15);
    }
  }
}
