.ribbon {
  background-color: red; //#cccccc; //rgba(170, 0, 0, 0.5);
  left: -3.5em;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  overflow: hidden;
  position: fixed;
  bottom: 2em;
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.75;

  a {
    display: block;
    margin: 1px 0;
    padding: 6px 50px 10px 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    //text-shadow: 0 0 5px #444;
    pointer-events: none;

    color: white;
    font-size: 12px;
    font-weight: 300;
  }
}
