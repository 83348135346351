@import "../../../../styles/color.scss";

.content {
  .sub-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__col--left {
      margin-right: 16px;
    }

    &__col--center {
      flex-grow: 1;
    }
  }
}
