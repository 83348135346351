@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

.report-problem {
  .ant-modal-header .ant-modal-title {
    @include font(16, 500, $blue-dark, null, 24);
  }

  // override default whitch is right
  .ant-col.ant-form-item-label {
    text-align: left;
    white-space: normal;
    line-height: 21px;
    margin-bottom: 12px;
  }

  // label for text area and drag and drop upload
  &__label {
    @include font(14, null, $text-color, null, 21);
  }

  // removed bottom padding
  .ant-modal-body {
    padding: 24px 24px 0 24px;
  }

  // the message containing the API call result,
  // it could be nothing (initial state), success, error
  &__send-message {
    @include font(36px, 500, $blue-dark, capitalize, 36);
    padding: 8px 0 24px 0;

    &--error {
      @include font(14, 500, $error-red, uppercase, null);
      letter-spacing: 1.87px;
    }

    &--success {
      @include font(14, 500, $blue-dark, uppercase, null);
      letter-spacing: 1.87px;
    }
  }

  .ant-list-split .ant-list-item {
    font-size: 12px;
    // the border between file list items
    border: none;
  }

  // the padding between file list items
  .ant-list-sm .ant-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
}
