// todo : verify them all (copied from https://github.com/LukasMarx/react-file-upload/tree/master/app/src)
@import "../../../../styles/color.scss";

.dropzone {
  height: 200px;
  background-color: $white;
  border: 2px dashed rgb(187, 186, 186);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  width: 100%;
  cursor: pointer !important;

  &-icon.anticon {
    color: $blue-selected;
    width: 40px;
    font-size: 40px;
    cursor: pointer;
  }
}

.highlight {
  border: 2px dashed $blue-selected;
}

.FileInput {
  display: none !important;
}
