@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

.scrollbar {
  background-color: $white;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  &__item_counter {
    margin: 0 15px;
  }

  &__controls {
    max-width: calc(85%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  &__scroll-slider {
    padding: 0 5px;
    flex-grow: 1;
  }

  &__arrow-container {
    width: 36px;
    height: 40px;

    .ant-btn[disabled] {
      border: none !important;
      font-size: 20px !important;
      background: none;
    }
  }

  &__arrow {
    border: none !important;
    font-size: 20px !important;
    width: 36px !important;
  }
}

.scrollbar + .table {
  margin-top: 0;
}

.table {
  // three child (headers, data-columns and right arrow) showed inline
  display: flex;
  flex-direction: row;
  background-color: $white;
  margin-top: 15px;

  &__headers {
    // first column

    // text-transform: uppercase;
    white-space: nowrap; // avoids headers in multiple rows
    min-width: 15%;
  }

  &__content {
    // data columns (all ones minus the first one and the last one)

    display: flex; // to show columns inline
    flex-direction: row;
    overflow: hidden;
    position: relative;
    // content is sibling of header and sidebar (both fixed) and it must expands itself to occupy all available width
    flex: 1;
  }

  &__slider {
    position: relative;
    display: flex; // to show columns inline
    flex-direction: row;
  }

  //&__sidebar {
  //  width: 40px;
  //}

  &__header {
    @include font(14, null, $blue-dark, null, 22);
    border-top: 1px solid $grey-border;
    padding: 10px 15px;
    min-height: 43px;
    cursor: pointer;
    // text-transform: uppercase;
  }

  &__warning-container {
    height: 88px;
    line-height: 31px;
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
  }

  &__setup-container {
    height: 47px;
    padding-left: 10px;
    padding-bottom: 16px;
    // flex to make it vertically centered
    display: flex;
    align-items: flex-end;
  }

  &--selected {
    background-color: $green-selected;
    height: 100%;
  }
}

.table-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 30px;

  &__text {
    @include font(16, null, $grey-link, null, 24);

    &.as-button {
      cursor: pointer;
      justify-content: flex-start;
      display: flex;
      align-items: center;
    }

    &.no-pointer {
      cursor: auto;
    }
  }
}

// button "Options" and "Customize Technical Specification"
.button {
  &--white {
    background-color: $white !important;
    color: $blue-selected !important;
  }
}

.order-icon {
  display: block;
  float: right;
  padding: 2px;
  font-size: 18px;
}

.anticon.score-icon {
  padding: 4px;
  font-size: 16px;
  color: $green;
}

.table-results {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px;
    font-weight: normal;

    &.ant-table-column-sort {
      background-color: $green-selected;
    }
  }

  .ant-table-thead > tr > th {
    background-color: white;
    overflow: hidden;
    height: 90px;
  }

  /* Remove hover background on .table-results row */
  .ant-table-tbody > tr {
    &.ant-table-row-hover, &:hover {
      &:not(.ant-table-expanded-row) > td {
        &:not(.ant-table-column-sort) {
          background: none;
        }

        &.ant-table-column-sort {
          background-color: $green-selected;
        }
      }
    }
  }
}

.table-cell-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 47px;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-buttons-list {
  @include font(20, null, $blue-selected, null, 24);

  button.icon-button,
  .icon-button button {
    // override ANT's default button styles
    font-size: 20px !important;
    padding: 0 6px !important;
    height: 32px !important;
  }

  .highlighted {
    color: $blue-selected;
    border-radius: 2px;
    background-color: $blue-light;
  }
}

.anticon.missing-options-warning {
  color: $red-availability;
  display: block;
  font-size: 16px;
}

.best-choice-best-ratio {
  background-color: $black;
  color: $white;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;

  &--empty {
    background-color: transparent;
    color: transparent;
  }

  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cell-unit {
  @include font(15, bold, $text-color, null, 22);
  /*display: block;*/
  white-space: normal;
  overflow-wrap: break-word;
  overflow: hidden;

  &__vertical {
    padding: 10px;
  }

  &::after {
    content: "";
    display: none;
    width: 8px;
    height: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-left: 3px;
    background-color: $light-blue-availability;
  }

  &--red::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-left: 3px;
    background-color: $red-availability;
  }

  &--yellow::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-left: 3px;
    background-color: $yellow-availability;
  }

  &--green::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-left: 3px;
    background-color: $green-selected;
  }
}

.selected-unit {
  background-color: rgba(201, 201, 201, 0.1);
}

.lock-changing-unit {
  background-color: $blue-light-background;
}

.lock-changed-unit {
  animation-name: table__column-highlight;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
}

.locked-unit + .unlocked-unit {
  border-top: 3px solid $blue-selected;
}

.last-locked-unit {
  border-right: 3px solid $blue-selected;
}
