//$image: '../../../../assets/ventolina.svg';
$image: '../../../../assets/ventolina_plair.svg'; //use this for ventolina-luve

.loading {
  width: 100%;
  padding: 50px;

  @keyframes spinner {
    100% {
      transform: rotate(-1800deg); //use this for ventolina-luve
      //transform: rotate(3600deg);
    }
  }

  .loading-container {
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  .spinner {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: url($image) center center;
    background-size: contain;
    background-repeat: no-repeat;
    transform-origin: 50% 50%;
    animation: spinner 5s infinite alternate ease-in-out;
  }

  .loading-text {
    position: relative;
    z-index: 1;
    font-size: 1em;
    margin-left: 0.5em;
    margin-top: 30px;
  }
}
