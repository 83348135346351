@import "../../../../../styles/color.scss";

.enhanced-label-wrapper {
  position: relative;
}

.enhanced-label {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon--squared {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0); // $blue-light;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue-selected;
  }
}
