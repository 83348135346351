.calculator-results_error-display {

  display: flex;
  justify-content: center;
  font-size: 2em;

  &_inner {

    padding: 2rem;
    width: 70%;
    text-align: center;

    > span {

      display: block;
      margin: auto;
      padding: .25rem;
      text-align: center;

    }
  }

}