@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.project-detail {
  &__heading {
    padding: 40px 0;
  }

  &__details {
    @include font(32, 300, $text-color, null, 42);
  }

  .title {
    &__container {
      margin-bottom: 10px;
    }

    &__name {
      @include font(32, 500, $blue-dark, null, 42);
      position: relative; // needed to absolute positioning the edit icon on the right
      min-height: 48px;
      border: 0;
      padding-left: 0;
      background-color: inherit;

      .edit-icon {
        color: $blue-selected;
        background-color: $blue-light;
        position: absolute;
        right: 0;
      }
    }

    &__subtitle {
      height: 40px;
      margin-bottom: 20px;
    }

    &__shared-by {
      @include font(14, null, $grey-title, null, 32);
    }

    &__change-settings {
      float: right;
      color: $blue-selected;
      @include font(14, null, null, null, 32);
    }
  }

  &__search {
    margin: 20px 0 70px 0 !important;
  }

  // the table part
  .summary {
    background-color: $white;
    padding: 30px 50px;
    margin-bottom: 50px;

    &__item-unit-description {
      @include font(12, null, null, null, 14);
      color: $grey-title;
      //max-width: 400px; // ATTENTION: the width before ellipsis in static!!
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
    }

    th {
      // table heading text
      background-color: $white;
      @include font(16, bold, $text-color, null, 22);
    }

    // Edit / Options icons
    tbody .icons div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      // the Option Icon (with 3 dots)
      svg.icon__content {
        margin-right: 0; // override default 5px
        transform: rotate(90deg);
      }
    }

    .ant-table-tbody {
      .price {
        text-align: center;
        @include font(14, null, $blue-dark, null, 22);
      }

      tr {
        height: 65px; // fix equal height for all (sometimes appear different)
      }

      .note {
        position: relative;
      }
    }

    // table's headers cells
    .ant-table-thead > tr > th {
      padding: 16px 0 16px 16px;

      &:first-child {
        padding: 16px;
      }
    }

    // table's body cells
    .ant-table-tbody > tr > td {
      padding: 16px;
    }


    .ant-table.ant-table-default.ant-table-scroll-position-left {
      margin-bottom: 60px; // override space between table and its paginator
    }

    // override ANT paginator
    .ant-pagination-options {
      float: left; // the default paginator is placed at the right of the page numbers
      margin-right: 30px;

      &::before {
        // text "Rows per page"
        content: "Rows per Page";

        @include font(12, null, $text-color, null, 22);
        margin-right: 10px;
      }
    }
  }

  // right page part with 6 blue buttons
  .buttons {
    ul {
      list-style: none;

      li {
        margin-bottom: 20px;
      }
    }

    &__button {
      display: flex;
      justify-content: space-between; // moves the icon at the borders keeping the button text in the middle
      padding-left: 10px;
      padding-right: 10px;

      i,
      span,
      div {
        align-self: center;
      }

      i.anticon {
        font-size: 20px;
      }

      .svgIcon svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__hr {
    border-bottom: 1px solid #f0f0f0;
    height: 1px;
    margin-block-end: 0; // override default
    margin-block-start: 0; // override default
  }

  .section {
    border-radius: 2px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
    background: transparent;
    border: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    &__content {
      background-color: $white;
      padding: 16px;
      height: 112px; // 16+16px padding + 80px lightblue square
      display: flex;
      align-items: center;
    }

    &__symbol {
      display: inline-block;
      height: 80px;
      width: 80px;

      & > i {
        // the "i" tag contains the svg
        height: 100%;
        width: 100%;
        margin: 0; // override icon--blue's margin

        & > svg {
          height: 40px;
          width: 40px;
        }
      }
    }

    &__title {
      display: inline-block;
      @include font(24, null, $text-color, null, null);
      margin-left: 16px;
      cursor: pointer;

      &:hover {
        color: $blue-selected;
      }
    }
  }

  // the euro icon - default strikethrough
  .svgIcon {
    // standard version
    g > g > g > path {
      fill: $white;
    }

    // disabled version (added dynamically by ProjectDetails React component)
    &--disabled {
      g > g > g > path {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }

  // error message in case title is empty
  &__error {
    @include font(14, 500, $error-red, null, 1.5);
  }
}

// the (whole) popover
.ant-popover {
  max-width: 400px;
}

// the popover item(s)
.popover .ant-popover-inner-content > a {
  @include font(null, null, $aquamarine-dark, null, 32);
  color: $aquamarine-dark;
  line-height: 32px;

  &:hover {
    color: $blue-selected;
  }
}
