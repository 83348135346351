@import "../../styles/color.scss";

.has-dropdown-menu {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 0 !important;
  position: relative;
  cursor: auto !important;

  .section__symbol {
    margin-right: 16px;
  }

  > .common {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  > .ant-dropdown-trigger {
    cursor: pointer;
  }

  .anticon.anticon-down {
    font-size: 20px !important;
    transform: scale(1) !important;
  }

  .as-menu-opener:after {
    content: ">";
    position: absolute;
    top: 22%;
    right: 10%;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
  }
}

.has-not-dropdown-menu {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 0 !important;
  line-height: 24px !important;

  .section__symbol {
    margin-right: 16px;
  }
}

.asDropdown-menu {
  max-width: 370px;

  li {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    transition: all 0.25s ease;
    padding-left: 4px !important;
    padding-right: 8px !important;

    svg {
      fill: #000;
    }

    svg {
      transform: scale(0.5);
      transition: all 0.25s ease;
      width: 40px;
    }

    &:hover {
      background-color: rgb(230, 247, 255);

      svg {
        fill: $blue-selected;
      }
    }

    &.ant-menu-item {
      a {
        display: flex;
      }
    }
  }
}

.dropdown-menu-override {
  min-width: auto !important;
  max-width: 370px;

  &.usedInSidebar {
    left: 80px !important;
    top: 120px !important;
  }
}
