@import "../../../styles/color.scss";

$animation-delay: 2000ms;
$animation-duration: 1500ms;

.configurator-form {
  .ant-form-item {
    // the space under each item item
    margin-bottom: 8px;
  }

  .ant-form-item-control {
    // override default 39px (?!?)
    line-height: 1em;
  }

  .ant-input-number-input {
    width: 100%;
  }

  @media (min-width: 1024px) {
    .card {
      max-width: 33.3333%;
    }
    .large {
      max-width: 66.6666%;
    }
  }

  @media (min-width: 1280px) {
    .card {
      max-width: 25%;
    }
    .large {
      max-width: 40%;
    }
  }

  @media (min-width: 1600px) {
    .card {
      max-width: 20%;
    }
    .large {
      max-width: 35%;
    }
  }

  .card {
    display: flex;
    flex: 1 0 300px;

    &.large {
      flex: 1 0 400px;
    }

    .content {
      position: relative;
      margin-top: 30px;
      width: 100%;
      box-shadow: 0 0 9px $black-shadow;
      color: $text-color;

      .section-number {
        position: absolute;
        right: 25px;
        top: -20px;
        background-color: $aquamarine;
        line-height: 60px;
        width: 60px;
        text-align: center;
        border-radius: 50px;
        font-size: 0.9em;
        color: $aquamarine-dark;

        .big {
          font-size: 1.5em;
          font-weight: bold;
        }
      }
    }

    h2 {
      color: $blue-dark;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.14px;
      position: relative;
    }

    .description {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 1px;
      margin: 10px 0 15px 0;
    }

    .ant-form-item-label {
      line-height: 1em;
    }

    label {
      display: inline-block;
      font-size: 12px;
      line-height: 24px; // the lebel height box which contains the string itself (12px)
      // text-transform: uppercase;
      color: $text-color;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    img {
      max-width: 100%;
      margin: 15px 0;
    }
  }

  .blocking {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    opacity: 0;
    background-color: #040303;
    -webkit-animation-delay: $animation-delay;
    -webkit-animation-duration: $animation-duration;
    -webkit-animation-name: Blocking;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    animation-delay: $animation-delay;
    animation-duration: $animation-duration;
    animation-name: Blocking;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes Blocking {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.6;
    }
  }

  /* Standard syntax */
  @keyframes Blocking {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.6;
    }
  }
}
