@keyframes form-toggle {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bounds {
  &__application-type-form {
    animation-name: form-toggle;
    animation-duration: 0.5s;
  }
}
