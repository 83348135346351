@import "../../styles/color.scss";
@import "../../styles/mixins.scss";
@import "../../styles/icon";

.item-selection {
  background-color: $grey-background;
  @include font(32, 300, $text-color, null, 42);
  padding-bottom: 30px;

  &__title {
    padding: 40px 0 20px 0;
  }

  &__description {
    @include font(13, null, $text-color, null, 20);
    letter-spacing: 1px;
    margin-bottom: 40px;
  }

  &__row {
    padding: 20px 0;
    // necessary in case one menu has more items than the other fill the whole height
    display: flex !important;

    // take the last of its children ("item-selection__column") and remove
    // the margin right to compensate the misalignment with "gutter-bug" Ant bug
    :last-child {
      padding-right: 0 !important;
    }
  }

  &__column {
    // necessary in case one menu has more items than the other fill the whole height
    display: flex !important;
    flex-direction: column;
  }

  &__full-row {
    background-color: $white;
    display: flex !important;
    align-items: center;

    _height: 200px; // TODO remove
  }
}

.application-type {
  background-color: $white;
  // necessary in case one menu has more items than the other fill the whole height
  // flex: 1;
  height: 100%;

  &__heading {
    padding: 40px 0;
    border-bottom: 1px solid $grey-border;
    display: flex !important;
    align-items: center;
  }

  &__title {
    @include font(38, 500, $blue-dark, null, 49); // $text-color
  }

  &__text {
    @include font(21, 500, $blue-selected, null, 22);
  }

  &__item {
    display: flex !important;
    align-items: center;

    .icon--margined {
      margin: 10px 20px 10px 30px;
    }
  }

  &__list {
    padding: 20px 0;

    & .ant-list-split .ant-list-item {
      border: none;
      justify-content: normal;
    }
  }

  &__infobox {
    margin-left: 6px;
  }
}

.frozen .application-type a.application-type__item {
  opacity: 0.5;
  cursor: default;
}
