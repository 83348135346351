.two-lines {
  white-space: normal;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 21px;
  height: 42px;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  display: block;
}

.two-lines--ellipsis {
  position: absolute;
  top: 50%;
  right: 6px;
}