@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/helper";

.item {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  padding: 50px;
  background-color: $white;
  margin-top: 40px;

  &__heading {
    margin-bottom: 40px;
  }

  &__title {
    @include font(24, 500, $blue-dark, null, null);
  }

  &__features {
    padding: 30px;
    @include font(14, null, $blue-dark, null, 32);
    border: 1px solid $grey-border;
  }

  &__content {
    border: 1px solid $grey-border;
  }

  &__footer {
    border-radius: 0 0 2px 2px;
    background-color: $blue-light-background;
    @include font(14, null, $blue-logo, uppercase, 24);
    letter-spacing: 1px;
    text-align: center;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex !important;
  }

  &__figcaption {
    @include font(14, null, null, null, 24);
  }
}

.box {
  background-color: rgba(201, 201, 201, 0.1);
  padding: 20px 30px 0 20px !important;
  border: 1px solid $grey-border;
  // margin bottom to compensates the bottom border to be visibile only if the right list increases the height or the box itself
  // margin-bottom: -2px;

  &__total {
    @include font(14, null, $text-color, null, 24);
  }

  &__main-price {
    @include font(64, 700, $blue-dark, null, 85);

    &--euro {
      font-size: 32px;
    }

    &--smaller {
      font-size: 42px;
    }
  }

  &__detail {
    @include font(18, 400, $blue-dark, null, 24);
    margin-bottom: 20px;
    text-align: right;

    &--smaller {
      font-size: 14px;
      text-align: left;
    }

    &--price {
      font-weight: 700;
      text-align: right;
    }

    &--text-left {
      text-align: left;
    }
  }

  &__footer {
    @include font(14, null, $grey-link, null, 24);
    float: right;
    cursor: pointer;
  }

  &__arrow {
    color: $blue-selected;
  }

  // &--bordered-bottom {
  //   border-bottom: 1px solid $grey-border;
  // }

  &--bordered-right {
    border-right: 1px solid $grey-border;
  }

  &--padded {
    padding: 0 10px 10px 0 !important;
  }
}

.optionPrice {
  @include font(18, 400, $blue-dark, null, 24);
  padding: 6px 0;

  &--price {
    text-align: right;
  }

  &.level-1 {
    font-size: 14px !important;
    padding: 0 0 0 2em;
    text-indent: -2em;
  }
}

.optionsGroup .ant-modal-body {
  max-height: 60vh;
  overflow-y: scroll;
  padding-top: 14px;
  padding-bottom: 14px;

  .ant-row.optionsTotal {
    border-top: 1px solid $blue-dark;

    .optionPrice {
      color: gray;
      font-weight: bold !important;
    }
  }
}
