@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.modal-preview-ts, .modal-quote {
  max-width: 90% !important;
  width: auto !important;

  .ant-modal-body {
    max-height: 90vh;
    overflow-y: scroll;
  }
}

.ant-table-row.custom-footer td {
  font-weight: bold;

  &.right {
    text-align: right;
  }

  &.noBorder {
    border-width: 0;
  }
}

.ts-item-unit-description {
  @include font(12, null, null, null, 14);
  color: $grey-title;
}