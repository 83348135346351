@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.new-project {
  &__title {
    @include font(32, 300, $text-color, null, 42);
  }

  &__affix {
    @include font(12, null, $text-color, uppercase, 32);
  }

  &__line {
    color: $grey;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__change-settings {
    text-align: right;
  }

  // error message in case title is empty
  &__error {
    @include font(14, 500, $error-red, null, 1.5);
  }

  .select-portfolio-title {
    color: $blue-dark;
    font-weight: 700;
    font-size: 1rem;
  }

  .portfolio-list {
    display: flex !important;
    margin: 16px 0;

    .portfolio-list_item {
      margin-right: 16px;
    }

    label {
      margin-top: 16px;
    }

    img {
      background-color: rgb(255, 255, 255);
      border: 20px solid rgb(255, 255, 255);
    }
  }
}
