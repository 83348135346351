@import "../../../../styles/color.scss";

.icon-link {
  // the container <div>
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  background-color: $white;
  display: table;
  color: $text-color;

  &__wrapper {
    // sub-container <div> wraps the <a> tag in case the icon is a link
    text-align: center;
    vertical-align: middle;
    display: table-cell;
  }

  &__link {
    // the <a> tag in case the icon is a link
    color: $text-color;
  }

  &__icon {
    // the <i> tag created by AntDesign's Icon component
    width: 14px;
    height: 14px;
  }
}
