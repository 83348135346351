@import "../../styles/color.scss";
@import "../../styles/icon.scss";

.textIcon {
  width: 40px;
  height: 40px;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px 10px 30px;

  &.inUtilities {
    color: rgb(93, 93, 93);
    background-color: rgb(201, 201, 201);
  }

  &.inItemList {
    font-size: 0.8rem;
    font-weight: 400;
    color: $white;
    background-color: $blue-selected;
  }
}
