@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.customize {
  &__content {
    // item name, es "DESIGN-CONDENSER-R1234YF-AC-CU-1550663202117"
    .title {
      @include font(38, 500, $blue-dark, null, 50);
      margin: 0;
      padding: 40px 0 20px 0;
    }
  }

  &__title {
    @include font(32, 300, $text-color, null, 42);
  }

  &__print {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__item {
    @include font(38, 500, $blue-dark, null, 50);
    margin: 0;
  }

  &__row {
    display: flex !important; // needed to keep equal height to its children (each option)
  }

  &__cell {
    border-right: 1px solid rgba(201, 201, 201, 0.37);
    border-bottom: 1px solid rgba(201, 201, 201, 0.37);
    padding: 24px !important;
    min-height: 100% !important;

    &:last-child {
      border-right: none;
      padding: 24px 0 24px 24px !important;
    }

    &:first-child {
      padding: 24px 24px 24px 0 !important;
    }
  }

  &__form {
    width: 100%;
  }

  &__label {
    @include font(12, null, $text-color, uppercase, 15);
    margin: 2px 0;
  }

  &__heading {
    @include font(16, bold, $blue-dark, null, 20);
    letter-spacing: 1.14px;
    padding: 24px 24px 0 24px !important;
    border-right: 1px solid rgba(201, 201, 201, 0.37);

    &:last-child {
      border-right: none;
      padding: 24px 0 0 24px !important;
    }

    &:first-child {
      padding: 24px 24px 0 0 !important;
    }
  }

  &__select,
  &__input-number {
    width: 100% !important;
  }

  &__input.ant-input {
    margin-bottom: 20px;
  }

  &__radio {
    .ant-radio-wrapper {
      margin-bottom: 10px;
    }
  }

  &__upload {
    padding: 4px 11px;
    height: 30px;
  }

  &__file-list {
    max-width: 100%;
  }

  &__upload-button.ant-btn-background-ghost {
    background-color: $white !important;
    border-radius: 2px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

// hide default Ant list
.ant-upload-list.ant-upload-list-text {
  display: none;
}

.limit-text-tag {
  display: inline-block;
  width: calc(100% - 20px);
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}
