@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.publicPagesLayout {
  &__content.ant-row {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex !important; // makes right column to fit 100% height - set by the left column's background image
    max-width: 1230px;
    margin: 0 auto;
  }

  &__left {
    background-image: url("../../assets/images/Login/bg-blue.svg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px 0 0 10px;
    padding: 60px 60px 0 60px !important;
    background-position: bottom right;
  }

  &__description {
    padding-top: 50px;
    @include font(20, 300, $white, null, 30);
  }

  &__action {
    @include font(24, 700, null, null, 32);
    // in changeEmail.scss
    // @include font(null, 700, null, uppercase, null);
  }

  &__right {
    background-color: $white;
    border-radius: 0 10px 10px 0;
    padding: 130px 60px 0 60px !important;
    min-height: 563px !important; // differently from "/" page, the right height is lower than left page and sets the height for both and right columns; in this case a min-height is set to give the default min height to both columns, otherwise they would be lower
    box-shadow: 0 0 30px 0 rgba(201, 201, 201, 0.46);
  }

  &__input {
    @include font(15, 300, $text-color, null, 24);

    /*
    &--mail {
      color: $blue-selected !important;
    }
    */

    &.ant-input-lg {
      padding: 8px 11px; // override Ant default "6px 11px";
    }
  }

  // input labels
  .ant-form-item-required {
    @include font(14, 500, $text-color, uppercase, null);
    letter-spacing: 1.87px;

    &::before {
      content: none; // override Ant default "*" before label
    }
  }

  // error message above inputs
  .ant-form-item-control {
    @include font(null, null, $error-red, null, "1.5 !important");
  }

  // OVERRIDE in fondo al file
  // &__button.ant-btn.ant-btn-primary {
  //   min-width: 170px;
  //   text-transform: uppercase;
  //   position: absolute; // refers to parent container with class "registration-email__right" which has position: relative
  //   right: 60px;
  //   bottom: 60px;
  // }

  &__failure {
    @include font(14, 500, $error-red, uppercase, null);
    letter-spacing: 1.87px;
  }

  &__success {
    @include font(14, 500, $blue-dark, uppercase, null);
    letter-spacing: 1.87px;
  }
}

// from registrationConfirm.scss
// ----------------------------------
.ant-form-item-label label {
  @include font(14, 500, $blue-dark, uppercase, null);
  letter-spacing: 1.87px;

  &::before {
    content: none; // override Ant default "*" before label
  }
}

// from login.scss
// ----------------------------------
.publicPagesLayout {
  &__right.ant-col-8 {
    background-color: $white;
    border-radius: 0 10px 10px 0;
    padding: 130px 60px 0 60px !important;
    min-height: 560px !important;
    box-shadow: 0 0 30px 0 rgba(201, 201, 201, 0.46);
  }

  &__label,
  &__password-row {
    > div {
      margin-bottom: 10px; // space between the 2 labels and their inputs below
      line-height: normal;
    }
  }

  &__label {
    margin-bottom: 10px !important;
  }

  &__password-row {
    margin-bottom: 0 !important;
  }

  // password input
  &__password-row {
    &.ant-row.ant-form-item.ant-form-item-no-colon {
      // it's equivalent to ".ant-row.ant-form-item.ant-form-item-no-colon.publicPagesLayout__password-row"
      margin-bottom: 0;
    }
  }

  &__forgot-psw.ant-row.ant-form-item {
    margin-bottom: 12px;
  }

  // "forgot psw" and "Didn't receive confirmation instructions"
  &__link {
    float: right;
    margin-bottom: 0 !important;
    @include font(14, null, null, null, 32);

    .ant-form-item-control-wrapper .ant-form-item-control {
      line-height: 22px;
    }
  }

  &__resend-info {
    float: right;
    @include font(14, null, null, null, 22);

    .ant-form-item-control-wrapper .ant-form-item-control {
      line-height: 22px;
    }
  }

  &__bottom.ant-row {
    position: absolute;
    bottom: 20px;
  }

  &__new-user {
    color: $blue-dark;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;

    &--register {
      color: $blue-selected;
    }
  }

  // "Remember me" checkbox test
  &__remember {
    &.ant-form-item {
      margin-bottom: 12px;
    }

    .ant-form-item-control {
      line-height: 14px;
    }

    span {
      @include font(15, 500, $text-color, null, 22);
    }
  }

  // button positioned bottom right
  &__button.ant-btn.ant-btn-primary {
    min-width: 70px;
    text-transform: uppercase;
    position: absolute; // refers to parent container with class "publicPagesLayout__right" which has position: relative
    right: 60px;
    bottom: 60px;
  }
}

.ant-row.ant-form-item.publicPagesLayout__forgot-psw {
  .publicPagesLayout__link {
    line-height: 1.25 !important;
    margin: 16px 0;
  }
}

.privacy {
  &.ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-control {
    line-height: 14px;
  }

  .ant-checkbox {
    vertical-align: top;
    margin-top: 5px;
  }

  .ant-checkbox + span {
    padding-right: 0;
    display: inline-block;
    width: calc(100% - 24px);
    @include font(15, 500, $text-color, null, 22);
  }
}
