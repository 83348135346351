$blue-selected: #4290f8;
$blue-dark: #000066;
$blue-light: #ebf0ff;
$blue-light-background: #eaf0ff;
$blue-active: #3b70ff; // hovered item on an opened drop-down item
$blue-icon: #5080ff;
$blue-logo: #4a90e2;

$grey: #c9c9c9; // rgba(201, 201, 201, 0.5);
$grey-background: #f2f2f2;
$grey-border: #f0f0f0;
$black: #000;
$grey-title: #8c8c8c;
$grey-link: #8c8c8c;

$grey-border: #e9eff4;
$violet-switch: rgba(59, 112, 255, 0.1);
$black-shadow: rgba(0, 0, 0, 0.03);
$white: #fff;
$aquamarine: #b9fdee;
$aquamarine-dark: #000066;
$text-color: $black;
$red-availability: #fa5045;
$error-red: #f5222d;
$yellow-availability: #fad139;
$light-blue-availability: #9cedf2;
$green-selected: #e9eff4;
$green: #00db80;
