@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

.details {
  &__container {
    display: flex !important;
    padding: 56px 0 32px 56px;
    /* Attention:
    it should be "flex-flow: column wrap;" which is a shortcut for the two 
    - flex-direction: column;
    - flex-wrap: wrap;
    but in chrome, despite is supported, does not provide the same result, use below statement
    */
    flex-flow: wrap;
    border: 1px solid #e9eff4;
    border-radius: 2px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  }

  &__document-wrapper {
    width: 33%;
  }

  &__category {
    @include font(14, bold, $blue-dark, null, 32);
  }

  &__name {
    @include font(14, 300, $blue-dark, null, 32);
  }

  &__list {
    list-style-type: none;
    padding-inline-start: 0;
    line-height: 40px;
    padding-right: 60px;
  }
}
