@import "./color.scss";

.icon {
  &--blue {
    width: 32px;
    height: 32px;
    color: $blue-selected !important;
    border-radius: 2px;
    background-color: $blue-light;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }

  &--error {
    width: 32px;
    height: 32px;
    color: $error-red !important;
    border-radius: 2px;
    background-color: $blue-light;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }

  &__content {
    display: inline-block;
    width: 24px;
    height: 24px;
    fill: $white;
    vertical-align: middle;
    margin-right: 5px;
    line-height: 24px;

    div {
      width: 100%;
      height: 100%;
    }

    &--32 {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
  }

  &__no-border {
    border: none !important;
  }

  &--40 {
    width: 40px !important;
    height: 40px !important;
    fill: $white;
    // margin: 10px 50px 10px 30px;
    * {
      max-width: 40px;
      max-height: 40px;
    }
  }

  &--margined {
    margin: 10px 50px 10px 30px;
  }

  &--56 {
    width: 56px;
    height: 56px;
    fill: $white;
    margin: 10px 30px;
  }

  &--pointer {
    cursor: pointer;
  }
}
