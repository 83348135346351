.logo-luve {
  &--absolute {
    position: absolute;
    top: 18px;
    right: -10px;
  }

  svg {
    top: 2px;
    right: 30px;
    height: 54px;
    width: auto;
    position: relative;
  }
}

.logo-luve-header {
  // display: flex;
  margin-left: 23px;
  /* svg {
    top: 14px;
    right: 16px;
    width: auto;
    position: relative;
  } */
}