@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.home {
  &__sections {
    margin: 50px 0 0;

    .section {
      border-radius: 2px;
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
      background: transparent;
      border: 0;

      &__content {
        background-color: $white;
        padding: 16px;
        height: 112px; // 16+16px padding + 80px lightblue square
        display: flex;
        align-items: center;
      }

      &__symbol {
        // display: inline-block;
        height: 80px;
        width: 80px;
        margin-right: 16px;
        color: $blue-icon;

        & > i {
          // the "i" tag contains the svg
          height: 100%;
          width: 100%;
          margin: 0; // override icon--blue's margin

          & > svg {
            height: 40px;
            width: 40px;
          }
        }
      }

      &__title {
        // display: inline-block;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        // margin-left: 16px;
        margin: 0;
        @include font(24, null, $text-color, null, 24);

        &:hover {
          color: $blue-selected;
        }
      }
    }
  }

  .ant-carousel {
    margin-top: 16px;
    width: 100%;
    height: calc(100vw * 0.2); // Aspect ratio 10:2
    img.banner {
      width: 100%;
      height: calc(100vw * 0.2); // Aspect ratio 10:2
    }

    .slick-dots {
      bottom: -4px;
      height: 5px;

      li button {
        height: 5px;
        background-color: #666666;
      }

      li.slick-active button {
        background-color: $blue-selected;
      }
    }
  }

  &__hr {
    border-bottom: 1px solid #f0f0f0;
    height: 1px;
    margin-block-end: 0; // override default
    margin-block-start: 16px; // override default
  }

  &__heading {
    @include font(16, bold, $aquamarine-dark, null, 20);
    letter-spacing: 1.14px;
    // line-height: 20px;
    padding: 30px 0 20px 0;
  }

  .summary {
    min-height: 600px; // TODO check value
    background-color: $white;
    padding: 30px 50px;
    margin-bottom: 50px;

    &__project-title {
      @include font(14, null, $grey-title, null, 22);
    }

    th {
      // table heading text
      background-color: $white;
      @include font(16, bold, $text-color, null, 2);
    }

    .icon {
      width: 50px;
      text-align: center;
    }

    .last-opened {
      width: 220px;
      text-align: center;
    }

    .note {
      position: relative;
    }

    .ant-table.ant-table-default.ant-table-scroll-position-left {
      margin-bottom: 60px; // override space between table and its paginator
    }

    // override ANT paginator
    .ant-pagination-options {
      float: left; // the default paginator is placed at the right of the page numbers
      margin-right: 30px;

      &::before {
        // text "Rows per page"
        content: "Rows per Page";
        @include font(12, null, $text-color, null, 22);
        margin-right: 10px;
      }
    }
  }
}

.home__sections + .home__hr {
  margin-block-start: 50px;
}

.cpq {
  @include font(16, bold, #223548, null, 22);
}