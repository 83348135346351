@import "../../../../styles/color.scss";

.configurator-wrapper {
  // AndD bug - https://github.com/ant-design/ant-design/issues/10144
  overflow: hidden;
}

.content {
  margin-bottom: 30px;

  .align-right {
    text-align: right;
  }

  .calculationResultsOf {
    text-align: right;
    color: $grey-title;
    margin-top: 3px;
  }

  .heading {
    padding: 40px 0;

    // "Design / Rating" switch button wrapper
    .radio-group {
      background-color: $violet-switch;
      border-radius: 2px;
      width: 100%;
      max-width: 300px;
      border: none;
      float: right;
    }

    & .radio-group_active {
      background-color: #fff;
    }

    // "Design / Rating" single button instance
    .ant-radio-group-large label.ant-radio-button-wrapper {
      width: calc(50% - 10px);
      text-align: center;
      background-color: transparent;
      // height: 100%;
      border: none;
      margin: 5px;
      height: 40px;
      line-height: 40px;
    }

    // selected button
    .ant-radio-group-solid .ant-radio-button-wrapper-checked {
      // left blue shadow happens to show on the left when second button is selected
      box-shadow: none;

      &:not(.ant-radio-button-wrapper-disabled) {
        border: none;
        background-color: $white;
        color: $blue-selected;
        margin: 5px;
      }
    }

    .ant-input-affix-wrapper {
      height: 50px;

      input {
        border: 1px solid #e9e9e9;
      }

      .ant-btn.ant-input-search-button.ant-btn-primary.ant-btn-lg {
        height: 48px;
      }
    }

    // override default Ant's switch button pipe bar divides buttons
    .ant-radio-button-wrapper:not(:first-child)::before {
      content: none;
    }

    .title {
      color: $blue-dark;
      font-size: 38px;
      font-weight: 500;
      margin: 0;
      line-height: 50px;
      min-height: 50px;

      &__input-item {
        color: $blue-dark;
        background-color: inherit;
        font-family: inherit;
        font-size: 38px;
        font-weight: 500;
        line-height: 42px;
        height: 48px;
        border: 0;
        padding-left: 0;
      }

      .edit-icon {
        color: $blue-selected;
        background-color: $blue-light;
      }
    }

    .saved-at {
      bottom: 0;
      color: $grey;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.stepper {
  display: flex !important;
  background: $grey;
  color: $white;
  font-size: 1.45em;
  font-weight: 300;
  border-radius: 4px;
  overflow: hidden;

  &__step {
    padding: 16px 16px 32px 16px !important;
    border-right: 1px solid #ccc;

    &:last-child {
      border-right: none;
    }

    &:first-child,
    &:last-child {
      border-radius: 2px 4px 4px 0;
    }

    &--selected {
      background: $blue-selected;

      a:hover {
        text-decoration: none;
        text-decoration-color: transparent !important;
      }
    }

    a,
    a:hover {
      color: $white;
      text-decoration: none;
      text-decoration-color: transparent !important;
    }
  }

  &__icon {
    margin-right: 12px;
    line-height: 24px;
  }
}

.ant-radio-group.ant-radio-group-outline {
  display: block;

  .radio {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}
