@import "../../../../styles/color.scss";

.SidePanel {
  .ant-drawer-header,
  .ant-drawer-content {
    color: $blue-dark;
    background-color: $blue-light;
  }

  .ant-drawer-header {
    border-bottom: 0 none;

    .ant-drawer-title {
      color: $blue-dark;
    }
  }

  &.ant-drawer-right .ant-drawer-content-wrapper {
    top: 120px;
    height: calc(100% - 120px);
  }

  &-title {
    padding-top: 24px;
    text-transform: capitalize;
    font-weight: 700;
    color: $blue-dark;
  }

  .SidePanelItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 16px;

    &-description {
      font-weight: 700;
    }

    &-info {
      cursor: pointer;
    }
  }
}
