.calculator-results_error-tools {
  // width: 600px;
  margin: auto;

  //background: gray;
  //display: flex;
  //justify-content: space-around;
  text-align: center;

  div.table-footer__text {
    display: inline-flex;
    background: rgb(255, 255, 255);
    margin: 0 16px;
    padding: 8px 10px 8px 5px;
  }
}