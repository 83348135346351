@import "../../../../styles/color.scss";

.sidebar {
  background-color: $white;

  .sidebar_list {
    padding: 0;
    margin: 0;

    .sidebar_list_item {
      list-style: none;
      padding: 0;
      font-size: 24px;
      text-align: center;
      margin: 32px 0;

      .icon-link__icon {
        width: auto;
        height: auto;
      }

      a,
      svg {
        cursor: pointer;
        color: $blue-logo;
      }
    }
  }
}
