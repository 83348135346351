@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

@keyframes table__column-highlight {
  from {
    background-color: $blue-light-background;
  }
  to {
    background-color: transparent;
  }
}

.table {
  // three child (headers, data-columns and right arrow) showed inline
  display: flex;
  flex-direction: row;
  background-color: $white;
  margin-top: 15px;
  overflow: hidden;

  &__headers {
    // first column
    white-space: nowrap; // avoids headers in multiple rows
  }

  &__content {
    // data columns (all ones minus the first one and the last one)

    display: flex; // to show columns inline
    flex-direction: row;
    overflow: hidden;
    // content is sibling of header and sidebar (both fixed) and it must expands itself to occupy all available width
    flex: 1;
  }

  &__slider {
    display: flex; // to show columns inline
    flex-direction: row;
    // margin-left value is set dynamically by JS at runtime, and so it'd takes the below transition
    -webkit-transition: margin-left 0.5s ease 0s;
    -moz-transition: margin-left 0.5s ease 0s;
    -o-transition: margin-left 0.5s ease 0s;
    transition: margin-left 0.5s ease 0s;
  }

  &__sidebar {
    width: 40px;
  }

  &__column {
    text-align: center;

    max-width: 200px;

    &--affix > div.ant-affix {
      background-color: white;
    }
  }

  &__column-container {
    min-width: 130px;
    padding-right: 2px;
  }

  &--selected {
    background-color: $green-selected;
  }
}

.table-cell {
  @include font(14, 300, $text-color, null, null);

  border-top: 1px solid $grey-border;
  color: $text-color;
  min-height: 43px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow-wrap: break-word;

  .icon--blue {
    color: $blue-selected;
  }

  &__no-padding {
    padding: 0;
  }

  &__button {
    @include font(14, null, $blue-selected, null, 24);
    letter-spacing: 1px;

    .ant-btn {
      // override ANT's default button styles

      height: 30px !important;
      min-width: 96px;

      // override ANT's default&ghost button styles
      //.ant-btn-default.ant-btn-background-ghost {
      //  color: $grey;
      //  border-color: $grey;
      //  height: 30px;
      //}
    }
  }

  &__img-danger {
    padding: 5px 0 5px 0;
  }

  &--flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.ratingLink {
  color: $blue-selected;
  width: 70%;
  min-width: 96px;
  display: block;
  border: 1px solid $blue-selected;
  margin: auto;
  border-radius: 4px;
  padding: 2px 4px;

  &.disabled {
    color: $grey;
    border-color: $grey;
    pointer-events: none;
    cursor: not-allowed;
  }
}

/* tooltip on download/view drawing icons' hover */
.ant-tooltip {
  .ant-tooltip-inner {
    background-color: $blue-light-background;
    color: $text-color;
  }

  .ant-tooltip-arrow {
    background-color: $blue-light-background;
  }
}
