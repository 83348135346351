@import "../../../../styles/color.scss";

.checkbox-multiple,
.enhanced-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  min-height: 24px;
  position: relative;

  .ant-checkbox-wrapper {
    flex: 1;
  }

  .icon--squared {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    border-radius: 2px;
    background-color: $blue-light;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue-selected;
  }

  &.enhanced-option {
    _position: relative;
  }
}

/* 
  Custom CSS for error red rectangle:
  Given 5px padding to have a space between content and border
  Added then negative 6px margin  (5px padding + 1px border) to simulate
  any movement between the rectangle is show or not 
*/
.ant-form-item-control-wrapper .ant-form-item-control.has-error .checkbox__wrapper-group {
  border: 1px solid #f5222d; // Ant "@error-color" variable's value
  padding: 5px 5px 0 5px;
  margin: -6px -6px 4px -6px;
  border-radius: 4px;
  width: auto !important;
}
