@import "../../styles/color.scss";
@import "../../styles/mixins.scss";

.open {
  &__title {
    @include font(32, 300, $text-color, null, 42);
  }

  &__search {
    margin: 20px 0 70px 0 !important;
  }

  &__tabs {
    .ant-tabs-tab {
      width: 264px;
      text-align: center;
      margin: 0 !important;
      background-color: $blue-light !important;
      transition: background ease 0.5s !important;

      &.ant-tabs-tab-active {
        // the active tab, initially "My Project" (right now the unique one, too)
        color: $white;
        background: $blue-selected !important;
      }
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      // bottom-bar under active tab
      width: 100%; // otherwise stays below the 264px of its parent
      background-color: $aquamarine-dark;
    }

    .ant-tabs-bar {
      // remove space between tabs and content below (ant default)
      margin: 0;
    }
  }

  //  todo check if all the following classes are still be used
  .summary {
    // min-height: 600px; // TODO check value
    background-color: $white;
    padding: 30px 50px;
    margin-bottom: 50px;

    &__project-owner {
      @include font(14, null, $grey-title, null, 22);
    }

    th {
      // table heading text
      @include font(16, bold, $text-color, null, 22);
      background-color: $white;

      .date {
        width: 200px;
        text-align: center;
      }
    }

    // Edit / Options icons
    tbody .icons div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      // the Option Icon (with 3 dots)
      svg.icon__content {
        margin-right: 0; // override default 5px
        transform: rotate(90deg);
      }
    }

    // the table's rows
    .ant-table-tbody > tr {
      height: 65px; // fix equal height for all (sometimes appear different)
    }

    // the empty Icon header (with no text)
    .ant-table-thead .icons {
      width: 120px;
      text-align: center;
    }

    .date {
      width: 220px;
      text-align: center;
    }

    .ant-table.ant-table-default.ant-table-scroll-position-left {
      margin-bottom: 60px; // override space between table and its paginator
    }

    // override ANT paginator
    .ant-pagination-options {
      float: left; // the default paginator is placed at the right of the page numbers
      margin-right: 30px;

      &::before {
        // text "Rows per page"
        content: "Rows per Page";
        @include font(12, null, $text-color, null, 22);
        margin-right: 10px;
      }
    }
  }
}

.popover .ant-popover-inner-content {
  padding: 0;

  button {
    display: block;
    width: 100%;
    border: 0;
    text-align: left;

    &:hover {
      background-color: $blue-light;
    }
  }
}
