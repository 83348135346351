@import "../../../../styles/color.scss";

.popover {
  cursor: pointer;

  .messages {
    padding: 8px;

    > li {
      list-style: none;

      span {
        font-weight: 700;
      }
    }
  }
}

.cell-note {
  min-height: 42px;
  font-size: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .whisperer {
    margin-left: 12px;
  }

  .with-warning {
    color: $blue-icon;
  }

  .no-warning {
    color: $green;
  }
}
