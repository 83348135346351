@import "../../styles/color.scss";

body {
  margin: 0;
  padding: 0;
  height: 100%;

  #root {
    // ATTENTION: in the following rule the spaces must be kept --> "calc(100%+30px);" DO NOT work !!!!!
    // height: calc(100% - 30px); // 30px is the .footer height !
    height: 100%; // 30px is the .footer height !

    .root-layout {
      min-height: 100%;
      position: relative;
      padding-bottom: 30px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;

      color: $grey-title;
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 10px;
      line-height: 14px;
      padding: 0;
      background: #fff;
      border-top: 1px solid $grey-border;
    }

    .platformInfo {
      display: inline-block;
      vertical-align: middle;
      margin-right: 23px;
      width: calc(100% - 109px);

      span {
        white-space: nowrap;
      }
    }

    .logoFooter {
      display: inline-block;
      vertical-align: middle;
      width: 86px;
    }
  }
}

.headerWrapper {
  border-bottom: 1px solid $grey-border;

  &.ant-layout-header {
    padding: 18px 16px 18px 0 !important;
    display: inline-flex;
  }

  // the box with avatar, username and down arrow
  &__user-menu {
    margin: 0 40px 0 50px;
    padding-left: 20px;

    &:hover {
      border-radius: 2px;
      background-color: #ebf0ff;
    }

    display: flex;
    align-items: center;
  }

  &__user-type {
    height: 19px;
    margin-top: 4px;
    vertical-align: top;

    .plair-logo-container {
      height: 19px;
      width: 36px;
      background-color: $blue-selected;
      padding: 3px 6px;
      display: inline-block;
    }
  }

  // distance between icons block
  &__icons > i,
  &__icons > span,
  &__icons > img {
    margin-left: 38px;
  }

  &__logo {
    // logo Plair
    background-color: $blue-selected;
    height: 96px;
    width: 132px;
    margin-top: -18px;
    text-align: center;
    padding: 29px;

    // logo Luve
    &--luve {
      position: relative; // necessary for svg alignment

      svg {
        height: 90px;
        width: 132px;
        position: relative;
        top: -8px;
        right: 10px;
      }
    }

    // svg logo Plair
    svg {
      display: inline-block;
      width: 72px;
      height: 38px;
    }
  }

  &__title {
    padding-left: 40px;
    color: $grey-title;
    font-weight: 300;
    font-size: 24px;
    flex: 1;
  }

  &__user {
    color: $blue-selected;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    margin-left: 8px;
  }

  &.ant-layout-header {
    padding: 16px 0;
    background-color: $white;
    height: 97px;
  }

  .ant-avatar {
    top: unset;
  }

  .anticon {
    font-size: 24px;
    vertical-align: middle;

    &--16 {
      font-size: 16px !important;
    }

    &--blue {
      color: $blue-selected;
    }
  }
}

#showedMeasurementSystem {
  position: absolute;
  top: 70px;
  left: 173px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

/* ANTD bug: when using a Row with gutter={xx}, the page increases to the right by some pixels, adding a horizontal bar 
   Add the following class to the <Row> when a gutter is needed  compensate the ANTD problem */
.gutter-bug {
  margin-right: 0 !important;
}
