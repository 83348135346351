@import "../../../../styles/color.scss";

.infoBox {
  position: absolute;
  right: 0;
  bottom: 0;

  &__content {
    display: flex;
    flex-direction: column;

    max-width: 100%;

    img {
      display: block;
      max-width: 100%;
      margin: auto auto 12px;
    }

    p {
      max-height: 160px;
      overflow-y: auto;
    }
  }
}
