@import "../../../../styles/color.scss";
@import "../../../../styles/mixins.scss";

/*
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

// list container
.drag-drop {
  display: flex;
  // flex-direction: column;  // too long in the one column

  &__draggable {
    // both lists
    width: 100%;
  }

  // a single box field
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-left: 1px solid $grey-border;
    border-right: 1px solid $grey-border;
    border-bottom: 1px solid $grey-border;
    border-radius: 2px;
    background-color: $white;

    &:first-of-type {
      border-top: 1px solid $grey-border;
    }
  }

  // &__item:first-of-type {
  //   border-top: 1px solid $grey-border;
  // }

  // text element part
  &__item-text {
    flex: 1; // fill the remaning unused space
    // white-space: nowrap; // prevent text to go to a second line --> TODO evaluate if to do so or not
  }

  // version using width instead of flex
  // &__item-text {
  //   width: 100%;
  //   padding: 5px;
  // }

  &__header {
    @include font(13, null, $text-color, uppercase, 21);
    margin-bottom: 28px;
  }

  &__column {
    background-color: $white;
    min-width: 250px;
    width: 50%;
  }
}
