// base font-family for entire project
$font-family: "IBM Plex Sans", sans-serif;

// function creates standard font property
// pass it "null" or "false" (in order) if a parameter is not present
@mixin font($size, $weight, $color, $text-transform, $line-height) {
  // for any caller
  font-family: $font-family;

  @if ($size) {
    font-size: $size + px !important;
  }
  @if ($weight) {
    font-weight: $weight !important;
  }
  @if ($color) {
    color: $color !important;
  }
  @if ($text-transform) {
    text-transform: $text-transform !important;
  }
  @if ($line-height) {
    line-height: $line-height + px !important;
  }
}
