.scrollable-container {
  &__container {
    // data columns (all ones minus the first one and the last one)

    overflow: hidden;
    position: relative;
    // content is sibling of header and sidebar (both fixed) and it must expands itself to occupy all available width
    flex: 1;
  }

  &__slider {
    position: relative;
    display: flex; // to show columns inline
    flex-direction: row;
    transition: left 0.8s;
  }
}
